<template>
    <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> Individual Accomplishment Report</strong>
            <div class="card-header-actions">
              <a 
                href="#" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
              >
                <small class="text-muted">AR</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody >
                 <div class="row">
                      <div class="form-group">
                        <span class="ml-2">From: </span>
                        <input class="ml-2" type="date" v-model="from"/>
                      </div>
                      <div class="form-group">
                        <span class="ml-2">To: </span>
                        <input class="ml-2" type="date" v-model="to"/>
                      </div>
                      <button class="ml-2 btn btn-primary btn-sm" @click="searchLogs(from, to)" style="height:27px" v-if="!loading">Search</button>
                      <button class="ml-2 btn btn-primary btn-sm" type="button" disabled v-if="loading" style="height:27px">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Searching...
                      </button>
                      <button class="ml-2 btn btn-primary btn-sm d-flex ml-auto mr-2" style="height:27px" @click="printAR">Print</button>

                  </div>
                  <div id="ar">
                      <div class="container mt-4" >
                        <div class="text-right">
                            <h6>Ref. No. {{reference()}}</h6>
                        </div>
                        <div class="text-center">Republic of the Philippines</div>
                        <div class="text-center">Office of the President</div>
                        <div class="text-center font-weight-bold">COMMISION ON HIGHER EDUCATION</div>
                          <img
                            src="@/assets/logo/ched.png"
                            alt=""
                            width="80"
                            length="80"
                            style="position: absolute; margin-left: 21%; margin-top: -3%"
                          />
                        <div class="text-center">Caraga Administrative Region</div>
                        <div class="text-center font-weight-bold mt-4">WEEKLY PLAN AND ACCOMPLISHMENT REPORT</div>
                        <div class="text-center">Month/Year: {{getDate()}}</div>
                        <div>Division: <span class="font-weight-bold"> {{ getDivision($route.params.pID) }} </span></div>
                        <div>Unit/Section: <span class="font-weight-bold"> {{ getSection($route.params.pID) }} </span></div>

                        <div class="row mt-4" style="border:1px solid; border-bottom: hidden" >
                            <div class="col-md-4 text-center font-weight-bold" style="border:1px solid;border-top:hidden;border-left:hidden">PLAN</div>
                            <div class="col-md-8 text-center font-weight-bold" style="border:1px solid; border-left: hidden;border-top:hidden;border-right:hidden">ACCOMPLISHMENT REPORT</div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 text-center font-weight-bold" style="border:1px solid; border-top:hidden">Activities/Tasks</div>
                            <div class="col-md-8 text-center font-weight-bold">
                              <div class="row" style="border:1px solid; border-top: hidden;border-left:hidden">
                                    <div class="col-md-3" style="border:1px solid; border-top: hidden;border-left:hidden;border-bottom:hidden">Date</div>
                                    <div class="col-md-9">Activities/Tasks</div>
                              </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 text-left" style="border:1px solid;hidden;border-top: hidden">
                            <ul v-for="(plan,i) in get_plans" :key="i" class="mt-4">
                              <li @click="updatePlan(plan)" style="cursor:pointer !important">{{ plan.plan }}</li>
                            </ul>
                            </div>
                            <div class="col-md-8 text-left">
                              <div class="row" v-for="(get_accomplishment_date,i) in get_accomplishment_dates" :key="i">
                                    <div class="col-md-3" style="border:1px solid; border-top: hidden;border-top: hidden;border-left: hidden" >
                                      <div>{{ formatDate(get_accomplishment_date.accomplishment_date) }}</div>
                                    </div>
                                    <div class="col-md-9" style="border: 1px solid;  border-top: hidden;border-left:hidden; cursor: pointer"  >
                                      <ul v-for="(get_accomplishment,i) in get_accomplishmentsFunc(get_accomplishment_date.accomplishment_date)" :key="i">
                                          <li @click="updateActivities(get_accomplishment)">{{ get_accomplishment.accomplishment }} </li>
                                      </ul>
                                    </div>
                              </div>
                            </div>
                        </div>
                        <div >
                            <div class="row mt-4">
                              <div class="form-group col-md-6">
                                Prepared by: 
                                <div class="font-weight-bold mt-4">{{ getPreparedBy($route.params.pID)}}</div>
                                <div>{{getPreparedPosition($route.params.pID)}}</div>
                              </div>
                              <div class="form-group col-md-6">
                                  Reviewed<span v-if="$route.params.pID === '51'"> Noted</span> by: 
                                  <div class="font-weight-bold mt-4">{{getReviewed($route.params.pID)}}</div>
                                  <div>{{getReviewedPosition($route.params.pID)}}</div>

                                  <div class="font-weight-bold mt-4" v-if="$route.params.pID === '57'">NOEME ROSE D. MANI, CPA, MM</div>
                                  <div v-if="$route.params.pID === '57'">Supervising Education Program Specialist</div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                          
                              </div>
                              <div class="form-group col-md-6 mt-4" v-if="
                              $route.params.pID === '51' || $route.params.pID === '6' || $route.params.pID === '7' || $route.params.pID === '8' || $route.params.pID === '9' || $route.params.pID === '11' || $route.params.pID === '14' || $route.params.pID === '15' || $route.params.pID === '16' || $route.params.pID === '17' || $route.params.pID === '18' || $route.params.pID === '19' || $route.params.pID === '21' || $route.params.pID === '22' || $route.params.pID === '23' || $route.params.pID === '27' || $route.params.pID === '30' || $route.params.pID === '33' || $route.params.pID === '35' || $route.params.pID === '38' || $route.params.pID === '39' || $route.params.pID === '40' || $route.params.pID === '41' || $route.params.pID === '42' || $route.params.pID === '43' || $route.params.pID === '44' || $route.params.pID === '46' || $route.params.pID === '47' || $route.params.pID === '48' || $route.params.pID === '49' || $route.params.pID === '50' || $route.params.pID === '51' || $route.params.pID === '52' || $route.params.pID === '53' || $route.params.pID === '54' || $route.params.pID === '55' || $route.params.pID === '56' || $route.params.pID === '57' || $route.params.pID === '58'
                              ">
                                  Noted by: 
                                  <div class="font-weight-bold mt-4">{{getNoted($route.params.pID)}}</div>
                                  <div>{{getNotedPosition($route.params.pID)}}</div>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!------------ Start- Add Accomplishment Modal ------------>
    <div id="add-accomplishment" class="modal fade" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Accomplishment</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitAccomplishmentReport">
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <label class="font-weight-bold">Date</label>
                        <input type="date" v-model="ar.ar_date" class="form-control" />
                    </div>
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Accomplishment Report</label>
                        <textarea  class="form-control" v-model="ar.ar_text"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Save</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!------------ End - Add Accomplishment Modal ------------>

        <!------------ Start- Add Plan Modal ------------>
    <div id="add-plan" class="modal fade" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Plan</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitPlan">
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Plan</label>
                        <textarea  class="form-control" v-model="plan"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Save</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!------------ End - Add Plan Modal ------------>

  <!------------ Start- Edit Date Modal ------------>
    <div id="edit-date" class="modal fade" >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Date</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form>
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Date</label>
                        <input  type="date" class="form-control" v-model="editDate.accomplishment_date"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
        <!------------ End - Edit Date Modal ------------>

          <!------------ Start- Edit Activity/Task Modal ------------>
    <div id="edit-activities" class="modal fade" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Activities/Tasks - {{formatDate(editActivities.accomplishment_date)}}</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitUpdateActivities(editActivities.ar_accomplishment_id)">
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Activities/Task</label>
                        <textarea  type="text" class="form-control" v-model="editActivities.accomplishment"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
        <!------------ End - Edit Acitivty/Task Modal ------------>


   <!------------ Start- Edit Plan Modal ------------>
    <div id="edit-plan" class="modal fade" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Plan</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitUpdatePlan(editPlan.ar_plan_id)">
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <label class="mt-4 font-weight-bold">From</label>
                             <input  type="date" class="form-control" v-model="editPlan.from_date_plan"/>
                          </div>
                          <div class="col-md-6">
                            <label class="mt-4 font-weight-bold">to</label>
                             <input  type="date" class="form-control" v-model="editPlan.to_date_plan"/>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Plan</label>
                        <textarea  type="text" class="form-control" v-model="editPlan.plan"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
        <!------------ End - Edit Plan Modal ------------>

  </div>
</template>

<script>
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
}
Vue.use(VueHtmlToPaper, options);

export default {
  data() {
    return {
      from: "",
      to: "",
      editDate: {
        accomplishment_date: ''
      },
      editActivities: {
        accomplishment: ''
      },
      editPlan: {
        plan: '',
        from_date_plan: '',
        to_date_plan: ''
      },
      loading: false,
      loading1: false,
      ar: {
        ar_date: '',
        ar_text: ''
      },
      plan: '',
      get_plans: [],
      get_accomplishments: [],
      get_accomplishment_dates: [],
      regularEmployees: [
    
      ],
      chedEmployees: [
        { BiometricID: '8', FirstName: 'Kathleen', LastName: 'Castanares', prepared: 'KATHLEEN D. CASTAÑARES', p_position: 'Project Technical Staff I - Budget Assistant', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Chief Administrative Officer', noted: 'ALMA S. PATRON, RSW, LLB', n_position: 'OIC - Director IV'},
        { BiometricID: '9', FirstName: 'Sarah', LastName: 'Moron', prepared: 'SARAH T. MORON', p_position: 'Project Technical Staff I, UniFAST/Accounting Staff', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, PhD, RSW', n_position: 'OIC - Director IV'},
        { BiometricID: '14', FirstName: 'Calwin', LastName: 'Alegre', prepared: 'JOBERT CALWIN M. ALEGRE', p_position: 'Project Technical Staff III - MIS', reviewed: 'AMY C. SAAVEDRA, MSCA', r_position: 'Education Supervisor II - MIS Head', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '15', FirstName: 'Daisy', LastName: 'Grana', prepared: 'DAISY I. GRANA', p_position: 'Project Technical Staff II, UniFAST',  reviewed: 'EVER JOY RABADAN, MAEM, MSc.', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '16', FirstName: 'May Trinity', LastName: 'Narca', prepared: 'MAY TRINITY N. DE LA TORRE', p_position: 'Project Technical Staff II, UniFAST/RD Secretary', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'ALMA S. PATRON, RSW, LLB', n_position: 'OIC - Director IV'},
        { BiometricID: '17', FirstName: 'Alexis Dave', LastName: 'Sanmiguel', prepared: 'ALEXIS DAVE M. SAN MIGUEL', p_position: 'Project Technical Staff I', reviewed: 'CRISELDO C. CALINAWAN,PHD, PECE', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '18', FirstName: 'Krizia Meijel', LastName: 'Mellijor', prepared: 'KRIZIA MEIJEL MELLIJOR', p_position: 'Project Technical Staff II', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, PhD, RSW', n_position: 'OIC - Director IV'},
        { BiometricID: '21', FirstName: 'Heidi', LastName: 'Paradero', prepared: 'HEIDI M. PARADERO', p_position: 'Project Technical Staff II',  reviewed: 'EVER JOY RABADAN, MAEM, MSc.', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '22', FirstName: 'Jo', LastName: 'Orendain', prepared: 'Active', p_position: '', reviewed: '', r_position: '', noted: '', n_position: ''},
        { BiometricID: '23', FirstName: 'Eleazar', LastName: 'Sumalinog', prepared: 'ELEAZAR A. SUMALINOG', p_position: '', reviewed: '', r_position: '', noted: '', n_position: ''},
        { BiometricID: '30', FirstName: 'Ronan', LastName: 'Otaza', prepared: 'RONAN E. OTAZA', p_position: 'Project Technical Staff I - MIS', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '38', FirstName: 'Bill', LastName: 'Baconguis', prepared: 'BILL CARLO R. BACONGUIS', p_position: 'Project Technical Staff II, UniFAST/MIS',  reviewed: 'EVER JOY RABADAN, MAEM, MSc.', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '39', FirstName: 'Christine', LastName: 'Escol', prepared: 'CHRISTINE R. ESCOL', p_position: 'k12 Support Staff', reviewed: '', r_position: '', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '46', FirstName: 'Maria Mercedes', LastName: 'Rebita', prepared: 'MA. MERCEDES P. REBITA', p_position: 'Project Technical Staff I - Records Section', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'ALMA S. PATRON, RSW, LLB', n_position: 'OIC - Director IV'},
        { BiometricID: '51', FirstName: 'Gladys', LastName: 'Concha', prepared: 'GLADYS O. CONCHA', p_position: 'CHED-COA Support Staff', reviewed: 'JONIFEL T. SAN JUAN, CPA', r_position: 'State Auditor III', noted: 'JONIFEL T. SAN JUAN, CPA', n_position: 'State Auditor III'},
        { BiometricID: '52', FirstName: 'Kershey Reign', LastName: 'Dueñas', prepared: 'KERSHEY REIGN E. DUEÑAS', p_position: 'Project Technical Staff II, UniFAST', reviewed: 'EVER JOY RABADAN, MAEM, MSc.', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '53', FirstName: 'Mona Pia', LastName: 'Galing', prepared: 'MONA PIA G. GALING', p_position: 'Project Technical Staff I - HRDS', reviewed: 'FRANCIS D. ILAGAN', r_position: 'Administrative Officer III - HRMO', noted: 'ALMA S. PATRON, RSW, LLB', n_position: 'OIC - Director IV'},
        { BiometricID: '57', FirstName: 'Orpheus', LastName: 'Cubillas', prepared: 'ORPHEUS A. CUBILLAS', p_position: 'Project Technical Staff II - Sports Coordinator', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'GEORGE M. COLORADO, PHD, CESO III', n_position: 'Director IV'},
        { BiometricID: '63', FirstName: 'JESIELYN MAE', LastName: 'ADO', prepared: 'JESIELYN MAE I. ADO', p_position: 'Project Technical Staff II -  Regional Statistical Focal Officer', reviewed: 'AMY C. SAAVEDRA, MSCA', r_position: 'Education Supervisor II - MIS Head', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '66', FirstName: 'Ceaka Angel', LastName: 'Pacot', prepared: 'CEAKA ANGEL P. PACOT', p_position: 'Admin Staff', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '70', FirstName: 'Anilou', LastName: 'Cabarrubias', prepared: 'ANILOU G. CABARRUBIAS', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '75', FirstName: 'Hannah Franchesca', LastName: 'Tabal', prepared: 'HANNAH FRANCHESCA M. TABAL', p_position: 'Project Technical Staff II', reviewed: 'EVER JOY G. RABADAN, MAEM, MSC.', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '76', FirstName: 'Kate Russel', LastName: 'Militante', prepared: 'KATE RUSSEL B. MILITANTE', p_position: 'CoScho Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '77', FirstName: 'Jobelyn', LastName: 'Intan', prepared: 'JOBELYN INTAN', p_position: 'Accounting Support Staff', reviewed: 'GESELLE G. FRANCISCO, CPA', r_position: 'OIC - Chief Administrative Officer', noted: 'ALMA S. PATRON, RSW, LLB', n_position: 'OIC - Director IV'},
        { BiometricID: '78', FirstName: 'Edrin Kate', LastName: 'Salingay', prepared: 'EDRIN KATE N. SALINGAY', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '81', FirstName: 'Geliemae', LastName: 'Bajan', prepared: 'GELIEMAE G. BAJAN', p_position: 'Project Technical Staff II', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'OIC - Chief Administrative Officer', noted: 'ALMA S. PATRON, RSW, LLB', n_position: 'OIC - Director IV'},
        { BiometricID: '80', FirstName: 'Florence', LastName: 'Lapinig', prepared: 'FLORENCE LAPINIG', p_position: 'Project Technical Staff', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},



        //REGULAR  EMPLOYEES
        { BiometricID: '1', FirstName: 'Alma', LastName: 'Patron', prepared: 'ALMA S. PATRON', p_position: 'Chief Administrative Officer', reviewed: 'GEORGE M. COLORADO, PHD, CESO III', r_position: 'Director IV'},
        { BiometricID: '2', FirstName: 'Geselle', LastName: 'Francisco', prepared: 'GESELLE G. FRANCISCO', p_position: 'Accountant II', reviewed: 'ALMA SY-PATRON, PhD, RSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '3', FirstName: 'Francis', LastName: 'Ilagan', prepared: 'FRANCIS D. ILAGAN', p_position: 'Administrative Officer III', reviewed: 'ALMA SY-PATRON, PhD, RSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '4', FirstName: 'Haemaccel', LastName: 'Palima', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'ALMA SY-PATRON, PhD, RSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '5', FirstName: 'Misael', LastName: 'Cabang', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'ALMA SY-PATRON, PhD, RSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '13', FirstName: 'Julia Felisa', LastName: 'Martinez', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'GEORGE M. COLORADO, PHD, CESO III', r_position: 'Director IV'},
        { BiometricID: '26', FirstName: 'Angel', LastName: 'Asis', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'ALMA SY-PATRON, PhD, RSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '28', FirstName: 'Revelyn', LastName: 'Brina', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'ALMA SY-PATRON, PhD, RSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '59', FirstName: 'Noeme', LastName: 'Mani', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '32', FirstName: 'Criseldo', LastName: 'Calinawan', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '34', FirstName: 'Amy', LastName: 'Saavedra', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '36', FirstName: 'Fred', LastName: 'Amihan', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '37', FirstName: 'Rolyna', LastName: 'Trugillo', prepared: 'ALMA SY-PATRON', p_position: 'Chief Administrative Officer', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
      ],
      chedEmployeesUnit: [
        { BiometricID: '8', FirstName: 'Kathleen', LastName: 'Castanares', division: 'ADMINISTRATIVE', unit_section: 'BUDGET'},
        { BiometricID: '9', FirstName: 'Sarah', LastName: 'Moron', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '14', FirstName: 'Calwin', LastName: 'Alegre', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '15', FirstName: 'Daisy', LastName: 'Grana', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '16', FirstName: 'May Trinity', LastName: 'Narca', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '17', FirstName: 'Alexis Dave', LastName: 'Sanmiguel', division: 'TECHNICAL', unit_section: 'MARITIME'},
        { BiometricID: '18', FirstName: 'Krizia Meijel', LastName: 'Mellijor', division: 'ADMINISTRATIVE', unit_section: 'LGSO'},
        { BiometricID: '21', FirstName: 'Heidi', LastName: 'Paradero', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '22', FirstName: 'Jo', LastName: 'Orendain', division: 'ADMINISTRATIVE', unit_section: 'FRONT-DESK'},
        { BiometricID: '23', FirstName: 'Eleazar', LastName: 'Sumalinog', division: 'ADMINISTRATIVE', unit_section: 'GENERAL SERVICES'},
        { BiometricID: '30', FirstName: 'Ronan', LastName: 'Otaza', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '38', FirstName: 'Bill', LastName: 'Baconguis', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '39', FirstName: 'Christine', LastName: 'Escol', division: 'TECHNICAL', unit_section: 'k12'},
        { BiometricID: '46', FirstName: 'Maria Mercedes', LastName: 'Rebita', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '51', FirstName: 'Gladys', LastName: 'Concha', division: 'ADMINISTRATIVE', unit_section: 'CHED-COA'},
        { BiometricID: '52', FirstName: 'Kershey Reign', LastName: 'Dueñas', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '53', FirstName: 'Mona Pia', LastName: 'Galing', division: 'ADMINISTRATIVE', unit_section: 'HR'},
        { BiometricID: '57', FirstName: 'Orpheus', LastName: 'Cubillas', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '70', FirstName: 'Anilou', LastName: 'Cabarrubias', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '75', FirstName: 'Hannah Franchesca', LastName: 'Tabal', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '76', FirstName: 'Kate Russel', LastName: 'Militante', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '77', FirstName: 'Jobelyn', LastName: 'Intan', division: 'ADMIN', unit_section: 'ACCOUNTING'},
        { BiometricID: '78', FirstName: 'Edrin Kate', LastName: 'Salingay', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '80', FirstName: 'Florence', LastName: 'Lapinig', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '81', FirstName: 'Gelliemae', LastName: 'Bajan', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},

        //REGULAR EMPLOYEE
        { BiometricID: '1', FirstName: 'Alma', LastName: 'Patron', division: 'ADMINISTRATIVE', unit_section: 'ADMIN'},
        { BiometricID: '2', FirstName: 'Geselle', LastName: 'Francisco', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '3', FirstName: 'Francis', LastName: 'Ilagan', division: 'ADMINISTRATIVE', unit_section: 'HR'},
        { BiometricID: '4', FirstName: 'Haemaccel', LastName: 'Palima', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '5', FirstName: 'Misael', LastName: 'Cabang', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '13', FirstName: 'Julia Felisa', LastName: 'Martinez', division: 'TECHNICAL', unit_section: 'MONITORING AND EVALUATION'},
        { BiometricID: '26', FirstName: 'Angel', LastName: 'Asis', division: 'ADMINISTRATIVE', unit_section: 'BUDGET'},
        { BiometricID: '28', FirstName: 'Revelyn', LastName: 'Brina', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '59', FirstName: 'Noeme', LastName: 'Mani', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '32', FirstName: 'Criseldo', LastName: 'Calinawan', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '34', FirstName: 'Amy', LastName: 'Saavedra', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '36', FirstName: 'Fred', LastName: 'Amihan', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '37', FirstName: 'Rolyna', LastName: 'Trugillo', division: 'TECHNICAL', unit_section: 'MPSD'},
      ]
    }
  },
  mounted() {
   
  },
  methods: {

  printAR() {
     this.$htmlToPaper('ar');
  },
  updatePlan(planbyId) {
    $('#edit-plan').modal('show');
    this.editPlan = {...planbyId}
    console.log("plann", this.editPlan);
  },
  updateActivities(dateById){
    $('#edit-activities').modal('show');
    this.editActivities = {...dateById}
    console.log("asaa", this.editActivities);
  },

  reference() {

      let d = moment().format('L');
      let t = moment().format('LTS');

      return d + ' ' + t; 
   },
   get_accomplishmentsFunc(date) {

     const result = this.get_accomplishments.filter((ac) => ac.accomplishment_date === date);

     return result;
   },
   getDivision(id) {
    let division = '';
    this.chedEmployeesUnit.forEach((cosEmployee) => {
          if (cosEmployee.BiometricID == id) {
            division = cosEmployee.division;
          }
        });

        return division;
   },
   getSection(id) {
    let section = '';
    this.chedEmployeesUnit.forEach((cosEmployee) => {
          if (cosEmployee.BiometricID == id) {
            section = cosEmployee.unit_section;
          }
        });

        return section;
   },
   getPreparedBy(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.prepared;
          }
        });

        return prepared;
    },
    getPreparedPosition(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.p_position;
          }
        });

        return prepared;
    },
    getReviewed(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.reviewed;
          }
        });

        return prepared;
    },
    getReviewedPosition(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.r_position;
          }
        });

        return prepared;
    },
    getNoted(id) {

    let noted = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            noted = chedEmployee.noted;
          }
        });

        return noted;
    },
    getNotedPosition(id) {

    let noted = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            noted = chedEmployee.n_position;
          }
        });

        return noted;
    },
   formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY dddd");
        
    },
    async getPlan(from, to, pId) {
          try {
              const response = await axios.get('api/individual-get-plan/' + from + '/' + to + '/' + pId)
              .then((response) => {
              this.get_plans = response.data;  
              console.log("plans", this.get_plans);
              })
          } catch(error) {
              console.log("err", error);
              alert('Something went wrong!');
          }
      },
    getDate() {
      let m = this.from; // get the month
      let f = this.from; // get date from
      let t = this.to; // get date to

      let month =  String(m[5]) + String(m[6]);
      let year = String(m[0]) + String(m[1]) + String(m[2]) + String(m[3]);
      let dayTo = "";
      let dayFrom = "";
      
      this.months = month;
      this.years = year;

      if(this.to) {
        dayTo = String(t[8]) + String(t[9]);
      }
      if(this.from) {
        dayFrom = String(f[8]) + String(f[9]);
      }

      let d = new Date();

      if(month === '01') {
        return "January" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '02') {
        return "February" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '03') {
        return "March" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '04') {
        return "April" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '05') {
        return "May" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '06') {
        return "June" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '07') {
        return "July" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '08') {
        return "August" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '09') {
        return "September" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '10') {
        return "October" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '11') {
        return "November" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '12') {
        return "December" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else {
        return "";
      } 

    },
    searchLogs(from, to) {
      this.loading = true;
      axios
        .get(
          'api/individual-search-accomplishment-report/' + from + '/' + to + '/' + this.$route.params.pID
        )
        .then((response) => {
          console.log("accomplishment_reports=>", response);
          this.get_accomplishments = response.data.accomplishment_reports;
          this.get_accomplishment_dates = response.data.accomplishment_dates;
           this.getPlan(from, to, this.$route.params.pID);
           this.loading = false;
          // console.log("response", response);
 
        })
        .catch((error) => {
          this.loading = false;
          alert(error);
        });

    },
  }
}
</script>

<style>

</style>